<template>
    <section class="formulario">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-4 my-5">
                    <div class="infos" v-once>
                        <h6>
                            Tem interesse nesse Empreendimento?
                        </h6>
                        <p>
                            Preencha o formulário e entraremos em contato o mais breve possível.
                        </p>
                    </div>
                </div>
                <div class="col-lg-8">
                    <form @submit.prevent="sendMail">
                        <div class="row">
                            <div class="col-lg-6">
                                <label for="nome">
                                    Nome
                                </label>
                                <input type="text" id="nome" class="form-control" v-model="emailData.nome">
                            </div>
                            <div class="col-lg-6">
                                <label for="telefone">
                                    Telefone
                                </label>
                                <input type="text" id="telefone" class="form-control" v-model="emailData.telefone">
                            </div>
                            <div class="col-lg-6">
                                <label for="email">
                                    E-mail
                                </label>
                                <input type="email" id="email" class="form-control" v-model="emailData.from">
                            </div>
                            <div class="col-lg-6">
                                <div class="btn-submit mt-4">
                                    <input type="submit" value="ENVIAR" @click="atualizaStatus">
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import '@/components/FormContato/StyleForm.css';

import axios from 'axios';

export default {
    name: 'FormularioContato',
    data() {
        return {
            emailData: {
                from: '',
                nome: '',
                telefone: ''
            }
        }
    },
    methods: {
        sendMail() {
            axios.post('https://otimizacao1.aw8.com.br/aruana-garden/wp-json/ag/v1/send_mail', {
                from: this.emailData.from,
                nome: this.emailData.nome,
                telefone: this.emailData.telefone
            })
            .then(response => {
                console.log(response);
                alert('Email enviado com sucesso!');
                window.location.href = '/';
            })
            .catch(error => console.log('erro ao enviar e-mail', error));
        },

        atualizaStatus() {
            const btnEnviar = document.querySelector('input[type="submit"]');
            btnEnviar.value = "Enviando...";
        }
    }

}
</script>
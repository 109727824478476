<!-- eslint-disable no-unused-vars -->
<template>
  <section class="body">
    <HeaderVue/>
    <!-- <WppBtnVue/> -->
  </section>
  <section class="form" id="especialista">
    <FormularioContato/>
  </section>
  <section class="carrossel">
    <Carrossel/>
    <ImplantacaoVue/>
    <GaleriadePlantas/>
  </section>
  <FooterVue/>
</template>

<script>
import HeaderVue from './components/Header/Header.vue';
// import WppBtnVue from './components/WhatsappBtn/WppBtn.vue';
import FormularioContato from './components/FormContato/Formulario.vue';
import FooterVue from './components/Footer/Footer.vue';
import Carrossel from './components/Carrossel/Carrossel.vue';
import ImplantacaoVue from './components/Implantacao/Implantacao.vue';
import GaleriadePlantas from './components/GaleriaPlantas/GaleriaPlantas.vue';


export default {
  name: 'App',
  components: {
    HeaderVue,
    // WppBtnVue,
    FormularioContato,
    Carrossel,
    FooterVue,
    ImplantacaoVue,
    GaleriadePlantas
}
}
</script>

<style>
  * {
    text-decoration: none !important;
  }
  .body, .carrossel {
    background-image: url('./assets/imgs/banner_novo_desktop.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .body {
    height: 100vh;
  }
  .carrossel  {
    background-image: url('./assets/imgs/bg-2.webp');
  }
  ul {
    padding: 0 !important;
  }
  @media (max-width: 1873px) {
    .body {
      height: 92vh;
    }
  }
  @media (max-width: 1770px) {
    .body {
      height: 89vh;
    }
  }
  @media (max-width: 1666px) {
    .body {
      height: 86vh;
    }
  }
  @media (max-width: 1599px) {
    .body {
      height: 83vh;
    }
  }
  @media (max-width: 1499px) {
    .body {
      height: 79vh;
    }
  }
  @media (max-width: 1420px), (max-height: 768px) {
    .body {
      height: 87vh;
    }
  }
  @media (max-width: 1420px), (max-height: 720px) {
    .body {
      height: 98vh;
    }
  }
  @media (max-width: 1420px), (max-height: 660px) {
    .body {
      height: 114vh;
    }
  }
  @media (max-width: 1420px), (max-height: 606px) {
    .body {
      height: 121vh;
    }
  }
  @media (max-width: 1420px), (max-height: 549px) {
    .body {
      height: 130vh;
    }
  }
  @media (max-width: 1420px) {
    .body {
        height: 83vh;
    }
  }
  @media (max-width: 1399px) {
    .body {
      height: 77vh;
    }
  }
  @media (max-width: 1360px) {
    .body {
      height: 117vh;
    }
  }
  @media (max-width: 1059px) {
    .body {
      height: 62vh;
    }
  }
  @media (max-width: 765px) {
    .body {
      background-image: url('./assets/imgs/banner_novo_mobile.png');
    }
    .body {
      height: 105vh;
    }
    .carrossel  {
      background-image: url('./assets/imgs/bg-2.webp');
    }
  }
</style>
